@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .hoverEffect {
        @apply cursor-pointer hover:bg-gray-200 rounded-full transition duration-500 ease-out
    }

    .the-tab td {
        @apply border-0
    }
    .card {
        @apply shadow-md bg-white rounded-md border
    }
    .but {
        @apply max-h-[36px] disabled:bg-orange-300 text-white bg-basic hover:bg-basic_hov flex flex-row items-center justify-center px-4 py-2 text-sm font-medium rounded-lg focus:ring-4 focus:ring-violet-300 focus:outline-none
    }
    .but-emp {
        @apply max-h-[36px]  bg-white hover:bg-basic_hov border hover:text-white border-basic_hov text-basic_hov flex flex-row items-center justify-center px-4 py-2 text-sm font-medium rounded-lg focus:ring-4 focus:ring-violet-300 focus:outline-none
    }
    .but-red {
        @apply max-h-[36px]  bg-white hover:bg-danger border hover:text-white border-danger text-danger flex flex-row items-center justify-center px-4 py-2 text-sm font-medium rounded-lg focus:ring-4 focus:ring-violet-300 focus:outline-none
    }
    .but-dis {
        @apply max-h-[36px] cursor-not-allowed bg-[#ffaa90]
    }
    .but-file {
        @apply max-h-[36px] text-white border-0 mr-4 bg-basic hover:bg-basic_hov justify-center px-4 py-2 text-sm font-medium rounded-lg focus:bg-basic_foc
    }
    .inp {
        @apply shadow-md w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg !bg-white focus:ring-violet-500 focus:border-violet-500
    }
    .ever * {
        @apply !cursor-progress
    }
    .reg-user2 input {
        @apply py-3 
    }
    .reg-user select {
        @apply h-[45.6px]
    }
    /* tr:nth-child(n) {
        @apply even:bg-red-600
    } */
    /* td:nth-child(2) {
        @apply even:bg-red-600
    } */

    .table-bott select, .inp {
        @apply bg-white
    }

    .buble {
        @apply inline-flex items-center px-2 py-1 mr-2 text-sm font-medium text-blue-800 bg-blue-100 rounded
    }
}


.react-datepicker-popper {
    z-index: 15 !important;
}